import styled from 'styled-components'
import { ImSpinner2 } from 'react-icons/im'

const LoadingIcon = styled(ImSpinner2)`
  animation: icon-spin 1s infinite linear;

  @keyframes icon-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`

export default LoadingIcon
