import * as yup from 'yup'
import { RepairSelectFormData } from '../models/RepairSelectFormData'

const repairSelectFormSchema: yup.SchemaOf<RepairSelectFormData> = yup
  .object()
  .shape({
    reference: yup
      .array()
      .of(yup.string())
      .required('Une référence de réparation est requise'),
  })
  .defined()

export default repairSelectFormSchema
