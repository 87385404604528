import { useCallback, useEffect, useRef, useState } from 'react'
import {
  useDebounce,
  useMount,
  useWindowScroll,
  useWindowSize,
} from 'react-use'

const useSticky = <T extends HTMLElement>({
  marginBottom = 0,
}: {
  marginBottom: number
}) => {
  const { y } = useWindowScroll()
  const { height, width } = useWindowSize()
  const ref = useRef<T>(null)
  const [translateY, setTranslateY] = useState(0)
  const [translateYDebounced, setTranslateYDebounced] = useState(0)
  useDebounce(
    () => {
      setTranslateYDebounced(translateY)
    },
    100,
    [translateY]
  )

  const updateTranslateY = useCallback(() => {
    const top = ref.current?.parentElement?.getBoundingClientRect().top ?? 0
    const elHeight =
      ref.current?.parentElement?.getBoundingClientRect().height ?? 0
    const translateY = Math.min(
      0,
      Math.round(height - top - elHeight - marginBottom)
    )

    setTranslateY(translateY)
  }, [ref.current, y, height, width, marginBottom])

  useEffect(() => {
    updateTranslateY()
  }, [updateTranslateY])

  useMount(() => {
    const timeout = setTimeout(() => {
      updateTranslateY()
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  })

  return { ref, translateY: translateYDebounced }
}

export default useSticky
