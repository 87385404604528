import React from 'react'

const ClientOnly = ({ children }: { children: any }) => {
  const [hasMounted, setHasMounted] = React.useState(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return <></>
  }

  return children
}

export default ClientOnly
