import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTimeoutFn } from 'react-use'
import styled, { css } from 'styled-components'
import theme from '../theme/theme'
import { useWindowSize } from 'react-use'
import useRefs from '../hooks/useRefs'
import { Link } from 'gatsby'
import { castSaveRepairCode } from '../utils/castSaveRepairCode'
import useMediaQuery from '../hooks/useMediaQuery'
import { ProductType } from '../data/productTypes'

const DEBUG = false

export type IOption = {
  label: string
  color: string
  value: string
}

export type IRepairSelectOption = {
  label: string
  code: string
  options: IOption[]
  target: {
    type: 'front' | 'back'
    single: [number, number]
    double: [number, number]
  }
  repairDetail: Record<string, IRepairDetail>
}
export type IRepairDetail = {
  popularRanking?: number
  isService?: boolean
  icon?: string
}

const ImageWithPointsContainer = styled.span`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;

  & > span {
    position: relative;
    display: inline-block;
  }
`

const ImagePoint = styled.span`
  position: absolute;
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 100px;
  transform: translate(-50%, -50%);
`

const Container = styled.div`
  display: flex;
  position: relative;
`

const Lines = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
`

const OptionsContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 10px;
  gap: 16px;
`

const Row = styled.div`
  & > a {
    display: flex;
    align-items: stretch;
    gap: 6px;

    & > span:last-child {
      padding-left: 10px;
    }
  }
`

const OptionLink = styled(Link)`
  text-decoration: none;
`

interface OptionRadioProps {
  active?: 'true'
}

const OptionRadio = styled.span<OptionRadioProps>`
  position: relative;
  visibility: hidden;
  margin: 0;
  padding: 0;
  margin: 7px;
  cursor: pointer;

  &:after {
    content: '';
    width: 23px;
    height: 23px;
    border-radius: 100px;
    border: 1px solid ${({ theme }) => theme.colors.placeholder};
    display: inline-block;
    position: absolute;
    visibility: visible;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
  }

  ${({ active }) => {
    return (
      active &&
      css`
        &:after {
          background: ${({ theme }) => theme.colors.secondary};
          border-color: ${({ theme }) => theme.colors.secondary};
        }
      `
    )
  }}
`

const OptionLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};
  flex: 1;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`

interface GridProps {
  withFront: boolean
  withBack: boolean
}

const Grid = styled.div<GridProps>`
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  grid-template: 1fr / minmax(100px, 130px) 1fr;
  row-gap: 16px;

  ${({ withFront, withBack }) => {
    return (
      withFront &&
      withBack &&
      css`
        grid-template: auto auto / minmax(100px, 130px) 1fr;
      `
    )
  }}

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template: 1fr / 1fr minmax(336px, 1fr) 1fr;

    & > *[class*='OptionsFront'] {
      order: -1;

      & > *[class*='Row'] {
        & > a {
          flex-direction: row-reverse;

          & > span:last-child {
            justify-content: flex-end;
            text-align: right;
            padding-left: 0;
            padding-right: 10px;
          }
        }

        & *[class*='SubOptionList'] {
          justify-content: flex-end;
          padding-left: 0;
          padding-right: 38px;
        }
      }
    }
  }
`

interface ImageWithPointsProps {
  image: React.ReactNode
  options: IRepairSelectOption[]
  pointRefs: React.RefObject<HTMLSpanElement>[]
  pointRefsStartIndex?: number
  code?: string[]
  target: 'double' | 'single'
}

const ImageWithPoints: React.FC<ImageWithPointsProps> = ({
  image,
  options,
  pointRefs,
  pointRefsStartIndex = 0,
  code,
  target,
}) => (
  <ImageWithPointsContainer>
    <span>
      {image}
      {options.map((option, i) => (
        <ImagePoint
          key={i}
          ref={pointRefs[pointRefsStartIndex + i]}
          style={{
            left: `${option.target[target][0] * 100}%`,
            top: `${option.target[target][1] * 100}%`,
            visibility:
              !DEBUG && code != null && !!code?.find((x) => x == option.code)
                ? 'visible'
                : 'hidden',
          }}
        />
      ))}
    </span>
  </ImageWithPointsContainer>
)

interface OptionsProps {
  className?: string
  options: IRepairSelectOption[]
  inputRefs: React.RefObject<HTMLSpanElement>[]
  inputRefsStartIndex?: number
  onCodeChange?: (code: string) => void
  onValueChange?: (value: string) => void
  onSubValueChange?: (value: string, optionList: IOption[]) => void
  code?: string[]
  value: string[]
  subValue: string[]
  pathBuilder: (reference: string[]) => string
}

const Options: React.FC<OptionsProps> = ({
  className,
  options,
  inputRefs,
  inputRefsStartIndex = 0,
  onCodeChange,
  code,
  onValueChange,
  onSubValueChange,
  value,
  pathBuilder,
}) => {
  const isChecked = useCallback(
    (option: IRepairSelectOption) => {
      return code != null ? !!code.find((x) => x === option.code) : false
    },
    [code]
  )

  const handleCodeChange = useCallback(
    (option: IRepairSelectOption) =>
      (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()

        // if only one subOption send directly value of subOption
        if (option.options.length === 1) {
          const value = option.options[0].value
          onValueChange && onValueChange(value)
        } else if (option.options.length > 1) {
          const value = option.options[0].value
          onSubValueChange && onSubValueChange(value, [])
        }
        onCodeChange && onCodeChange(option.code)
      },
    [onCodeChange, onValueChange]
  )

  const codePathBuilder = useCallback(() => {
    return pathBuilder(value)
  }, [code])

  return (
    <OptionsContainer className={className}>
      {options.map((option, i) => (
        <Row key={i} className="Row">
          <OptionLink to={codePathBuilder()} onClick={handleCodeChange(option)}>
            <span ref={inputRefs[inputRefsStartIndex + i]}>
              <OptionRadio active={isChecked(option) ? 'true' : undefined} />
            </span>
            <OptionLabel>
              <h2>{option.label}</h2>
            </OptionLabel>
          </OptionLink>
        </Row>
      ))}
    </OptionsContainer>
  )
}

interface RepairSelectProps {
  images: {
    single: {
      front: React.ReactNode
      back: React.ReactNode
    }
    double: React.ReactNode
  }
  options: IRepairSelectOption[]
  onChange?: (value: string[]) => void
  value?: string
  pathBuilder: (reference: string[]) => string
  showMessage: (value: boolean) => void
}

// the order is very important, because we use the index to set the refs, so
// the front type options must be before the back type options
const useOptions = (options: IRepairSelectOption[]) => {
  return useMemo(() => {
    return [...options]
      .filter((option) => castSaveRepairCode(option.code))
      .sort((a, b) => b.target.type.localeCompare(a.target.type))
  }, [options])
}

const RepairSelect: React.FC<RepairSelectProps> = ({
  images,
  onChange,
  showMessage,
  options: optionsRaw,
  pathBuilder,
}) => {
  const options = useOptions(optionsRaw)

  //code reparation
  const [code, setCode] = useState<string[]>([])
  //ref reparation option
  const [value, setValue] = useState<string[]>([])
  //ref sub option (couleurs) selection unique
  const [subValue, setSubValue] = useState<string[]>([])

  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.md})`)
  // force rerender one to update lines
  const [isReady, setIsReady] = useState(false)
  useTimeoutFn(() => setIsReady(true))

  // force rerender after code update
  const [tick, setTick] = useState(false)
  useEffect(() => setTick((t) => !t), [code])

  const linesRef = useRef<SVGSVGElement | null>(null)
  const pointRefs = useRefs<HTMLSpanElement>(options.length)
  const inputRefs = useRefs<HTMLSpanElement>(options.length)

  const lines = useMemo(() => {
    return options.map((option, i) => {
      if (!DEBUG && !!!code.find((x) => x == option.code)) {
        return
      }

      const lines = linesRef.current?.getBoundingClientRect()
      const point = pointRefs[i]?.current?.getBoundingClientRect()
      const input = inputRefs[i]?.current?.getBoundingClientRect()

      if (!lines || !point || !input) {
        return
      }

      const x1 = point.x - lines.x + point.width / 2
      const x2 = input.x - lines.x + input.width / 2
      const y1 = point.y - lines.y + point.height / 2
      const y2 = input.y - lines.y + input.height / 2

      return { x1, y1, x2, y2 }
    })
  }, [
    linesRef,
    pointRefs,
    inputRefs,
    isReady,
    code,
    windowWidth,
    windowHeight,
    options,
    value,
    tick,
  ])

  const optionsFront = useMemo(
    () => options.filter((x) => x.target.type === 'front'),
    [options]
  )
  const optionsBack = useMemo(
    () => options.filter((x) => x.target.type === 'back'),
    [options]
  )

  const withFront = useMemo(() => optionsFront.length > 0, [optionsFront])
  const withBack = useMemo(() => optionsBack.length > 0, [optionsBack])

  //change
  const handleCodeChange = useCallback(
    (code: string) => {
      setCode((oldCodes) => {
        //if the code was already selected remove it ; if not add it
        if (!!oldCodes.find((x) => x === code)) {
          // if suboption get unchecked then remove also from the list
          for (const opt of options) {
            if (opt.options.length > 0 && opt.code === code) {
              setSubValue((oldValue) =>
                oldValue.filter((x) => !opt.options.find((y) => y.value === x))
              )
            }
          }

          return oldCodes.filter((x) => x !== code)
        }
        //limite à 3
        if (oldCodes.length < 3) {
          return [...oldCodes, code]
        }
        return oldCodes
      })
    },
    [onChange]
  )

  useEffect(() => {
    if (code.length >= 3) {
      showMessage(true)
    } else {
      showMessage(false)
    }
  }, [code.length])

  //option change
  const handleValueChange = useCallback(
    (newValue: string) => {
      setValue((oldValue) => {
        if (!!oldValue.find((x) => x === newValue)) {
          return oldValue.filter((x) => x != newValue)
        }
        //limite à 3
        else if (oldValue.length < 3) {
          return [...oldValue, newValue]
        } else {
          return oldValue
        }
      })
    },
    [onChange]
  )
  useEffect(
    () => onChange && onChange(value.concat(subValue)),
    [value, subValue]
  )

  //suboption change
  const handleSubValueChange = useCallback(
    (newValue: string, optionList: IOption[]) => {
      setSubValue((oldValue) =>
        !!oldValue.find((x) => x === newValue) //if suboption already selected ? remove it : remove all suboption and add new one
          ? oldValue.filter((y) => y !== newValue)
          : [
              ...oldValue.filter((x) => !optionList.find((y) => x === y.value)),
              newValue,
            ]
      )
    },
    [onChange]
  )

  return (
    <Container>
      <Grid withFront={withFront} withBack={withBack}>
        {isDesktop ? (
          <ImageWithPoints
            image={images.double}
            target="double"
            options={options}
            pointRefs={pointRefs}
            code={code}
          />
        ) : (
          <>
            {withFront && (
              <ImageWithPoints
                image={images.single.front}
                target="single"
                options={optionsFront}
                pointRefs={pointRefs}
                code={code}
              />
            )}
            {withBack && (
              <ImageWithPoints
                image={images.single.back}
                target="single"
                options={optionsBack}
                pointRefs={pointRefs}
                pointRefsStartIndex={optionsFront.length}
                code={code}
              />
            )}
          </>
        )}

        {withFront && (
          <Options
            className="OptionsFront"
            options={optionsFront}
            code={code}
            value={value}
            subValue={subValue}
            inputRefs={inputRefs}
            onCodeChange={handleCodeChange}
            onValueChange={handleValueChange}
            onSubValueChange={handleSubValueChange}
            pathBuilder={pathBuilder}
          />
        )}
        {withBack && (
          <Options
            options={optionsBack}
            code={code}
            value={value}
            subValue={subValue}
            inputRefs={inputRefs}
            inputRefsStartIndex={optionsFront.length}
            onCodeChange={handleCodeChange}
            onValueChange={handleValueChange}
            onSubValueChange={handleSubValueChange}
            pathBuilder={pathBuilder}
          />
        )}
      </Grid>

      <Lines ref={linesRef} width="10000" height="10000">
        {lines.map(
          (line, i) =>
            line && (
              <line
                key={i}
                x1={line.x1}
                y1={line.y1}
                x2={line.x2}
                y2={line.y2}
                stroke={theme.colors.secondary}
                strokeWidth={2}
              />
            )
        )}
      </Lines>
    </Container>
  )
}

export default RepairSelect
