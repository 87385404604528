import styled, { css } from 'styled-components'
import { IRepairSelectOption } from './RepairSelect'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Container = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 70px;
    margin: 10px 0 0;
  }
`
const AllRepairContainer = styled.div``
const CategoryRepairContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, auto));
  gap: 10px;
  justify-items: center;

  @media (min-width: 590px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 890px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
`

const FieldSet = styled.fieldset`
  margin: 8px 0 19px 0;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  padding: 18px;
  border-radius: 5px;
  overflow: hidden;
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
`

const Label = styled.legend`
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 5px;
  display: inline-block;
  padding: 0 7px;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
`
interface OptionProps {
  active?: 'true'
}

const RepairsContainer = styled.div<OptionProps>`
  display: inline-block;
  position: relative;
  border: 1px solid rgb(218, 225, 231);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 0 0.00999832px;
  width: 100%;
  height: 50px;
  background-color: white;
  ${({ active }) => {
    return (
      active &&
      css`
        background-color: ${({ theme }) => theme.colors.secondary};

        & > a {
          color: white;
        }
      `
    )
  }}
`
const LabelDiv = styled.div`
  @media (max-width: 422px) {
    width: 270px;
  }
`
const Img = styled.img``

const OptionLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 10px;
  gap: 4.18px;
  height: 50px;
  color: black;
  width: 100%;
`

interface RepairOtherSelectProps {
  name: string
  imageFile?: {
    publicURL?: string | null
    childImageSharp?: {
      gatsbyImageData?: IGatsbyImageData | null
    } | null
  } | null
  options: IRepairSelectOption[]
  onChange?: (value: string[]) => void
  pathBuilder: (reference: string[]) => string
  showMessage: (value: boolean) => void
  productTypeCode?: string
  smartphoneLabel: string
}

const compareRepairPriority = (
  a: IRepairSelectOption,
  b: IRepairSelectOption,
  productTypeCode: string
): number => {
  const popularRankingA = a.repairDetail[productTypeCode]?.popularRanking
  const popularRankingB = b.repairDetail[productTypeCode]?.popularRanking

  if (popularRankingA === undefined && popularRankingB === undefined) {
    return 0 // If both are undefined, consider them equal
  } else if (popularRankingA === undefined) {
    return 1 // If only 'a' is undefined, consider 'b' greater
  } else if (popularRankingB === undefined) {
    return -1 // If only 'b' is undefined, consider 'a' greater
  } else {
    return popularRankingA - popularRankingB // Compare normally if both are defined
  }
}

const RepairOtherSelect: React.FC<RepairOtherSelectProps> = ({
  options,
  onChange,
  pathBuilder,
  showMessage,
  productTypeCode,
}) => {
  const sortedRepairs = useMemo(() => {
    if (!options) return []
    return options?.sort((a, b) =>
      compareRepairPriority(a, b, productTypeCode ?? '')
    )
  }, [options, productTypeCode])

  //get most popular repairs
  const popularRepairs = useMemo(
    () =>
      sortedRepairs
        ?.filter(
          (repair) =>
            !!!repair.repairDetail[productTypeCode ?? '']?.isService &&
            !!repair.repairDetail[productTypeCode ?? '']?.popularRanking
        )
        .slice(0, 3),
    [sortedRepairs, productTypeCode]
  )
  //get services
  const services = useMemo(
    () =>
      sortedRepairs?.filter(
        (repair) => !!repair.repairDetail[productTypeCode ?? '']?.isService
      ),
    [sortedRepairs, productTypeCode]
  )
  //get other repairs
  const otherRepairs = useMemo(
    () =>
      sortedRepairs.filter(
        (repair) =>
          !!!repair.repairDetail[productTypeCode ?? '']?.isService &&
          !!!repair.repairDetail[productTypeCode ?? '']?.popularRanking
      ),
    [sortedRepairs, productTypeCode]
  )

  //repairs options select
  //code reparation
  const [code, setCode] = useState<string[]>([])
  //ref reparation option
  const [value, setValue] = useState<string[]>([])

  //change
  const handleCodeChange = useCallback(
    (code: string) => {
      setCode((oldCodes) => {
        //if the code was already selected remove it ; if not add it
        if (!!oldCodes.find((x) => x === code)) {
          return oldCodes.filter((x) => x !== code)
        }
        //limite à 3
        if (oldCodes.length < 3) {
          return [...oldCodes, code]
        }
        return oldCodes
      })
    },
    [onChange]
  )

  useEffect(() => {
    if (code.length >= 3) {
      showMessage(true)
    } else {
      showMessage(false)
    }
  }, [code.length])

  //option change
  const handleValueChange = useCallback(
    (newValue: string) => {
      setValue((oldValue) => {
        if (!!oldValue.find((x) => x === newValue)) {
          return oldValue.filter((x) => x != newValue)
        }
        //limite à 3
        else if (oldValue.length < 3) {
          return [...oldValue, newValue]
        } else {
          return oldValue
        }
      })
    },
    [onChange]
  )

  useEffect(() => onChange && onChange(value), [value])

  return (
    <Container>
      <AllRepairContainer>
        <RepairSelectOptions
          label="Pannes courantes"
          repairs={popularRepairs}
          code={code}
          value={value}
          onCodeChange={handleCodeChange}
          onValueChange={handleValueChange}
          productTypeCode={productTypeCode}
          pathBuilder={pathBuilder}
        ></RepairSelectOptions>
        {otherRepairs && otherRepairs.length > 0 && (
          <RepairSelectOptions
            label="Autres pannes"
            repairs={otherRepairs}
            code={code}
            value={value}
            onCodeChange={handleCodeChange}
            onValueChange={handleValueChange}
            productTypeCode={productTypeCode}
            pathBuilder={pathBuilder}
          ></RepairSelectOptions>
        )}
        {services && services.length > 0 && (
          <RepairSelectOptions
            label="Services"
            repairs={services}
            code={code}
            value={value}
            onCodeChange={handleCodeChange}
            onValueChange={handleValueChange}
            productTypeCode={productTypeCode}
            pathBuilder={pathBuilder}
          ></RepairSelectOptions>
        )}
      </AllRepairContainer>
    </Container>
  )
}

interface RepairSelectOptionsProps {
  productTypeCode?: string
  repairs: IRepairSelectOption[]
  label: string
  code?: string[]
  value: string[]
  pathBuilder: (reference: string[]) => string
  onCodeChange?: (code: string) => void
  onValueChange?: (value: string) => void
}

const RepairSelectOptions: React.FC<RepairSelectOptionsProps> = ({
  productTypeCode,
  repairs,
  label,
  onCodeChange,
  code,
  onValueChange,
  value,
  pathBuilder,
}) => {
  const isChecked = useCallback(
    (option: IRepairSelectOption) => {
      return code != null ? !!code.find((x) => x === option.code) : false
    },
    [code]
  )

  const handleCodeChange = useCallback(
    (option: IRepairSelectOption) =>
      (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()

        const _value = option.options[0].value
        onValueChange && onValueChange(_value)

        onCodeChange && onCodeChange(option.code)
      },
    [onCodeChange, onValueChange]
  )

  const codePathBuilder = useCallback(() => {
    return pathBuilder(value)
  }, [code])

  return (
    <>
      <div style={{ position: 'relative' }}>
        <FieldSet>
          <Label>{label}</Label>
          <CategoryRepairContainer>
            {repairs.map((repair) => (
              <RepairsContainer
                key={repair.code}
                active={isChecked(repair) ? 'true' : undefined}
              >
                <OptionLink
                  to={codePathBuilder()}
                  onClick={handleCodeChange(repair)}
                >
                  {isChecked(repair) ? (
                    <Img
                      alt={repair.code}
                      src={repair.repairDetail[
                        productTypeCode ?? ''
                      ]?.icon?.replace('.svg', '_WHITE.svg')}
                      width={25}
                      height={25}
                      margin-left={"15px"}
                    />
                  ) : (
                    <Img
                      alt={repair.code}
                      src={repair.repairDetail[productTypeCode ?? '']?.icon}
                      width={25}
                      height={25}
                      margin-left={"15px"}
                    />
                  )}
                  <LabelDiv>
                    <h2>{repair.label}</h2>
                  </LabelDiv>
                </OptionLink>
              </RepairsContainer>
            ))}
          </CategoryRepairContainer>
        </FieldSet>
      </div>
    </>
  )
}

export default RepairOtherSelect
