import styled from 'styled-components'

const RepairSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 10px 10px;
  gap: 10px;
`

export default RepairSelectContainer
