import { createRef, RefObject, useEffect, useState } from 'react'

const useRefs = <T>(length: number) => {
  const [refs, setRefs] = useState<RefObject<T>[]>([])

  useEffect(() => {
    setRefs((elRefs) =>
      Array.from({ length }).map((_, i) => elRefs[i] || createRef())
    )
  }, [length])

  return refs
}

export default useRefs
