import { IRepairSelectOption } from '../components/RepairSelect'
import repairPoints from './repairPoints'
import { ISaveRepairCode } from './saveRepairCode'

const saveRepairPoints: Record<ISaveRepairCode, IRepairSelectOption['target']> =
  {
    BAT: repairPoints.BATTERY,
    CAM_FRONT: repairPoints.CAMERA_FRONT,
    LCD: repairPoints.SCREEN,
    BACK_COVER: repairPoints.BATTERY,
    CAM_REAR: repairPoints.CAMERA_BACK,
    DIAG: repairPoints.SCREEN,
    RNL: repairPoints.SCREEN,
    TCT: repairPoints.SCREEN,
    DOX: repairPoints.SCREEN,
    BTN_VIB: repairPoints.BATTERY,
    VIB: repairPoints.BATTERY,
    PCONNECTOR: repairPoints.MICRO,
    ECO: repairPoints.EARPHONE,
    HP: repairPoints.SPEAKER_SIDE,
    'HAUT PARLEUR': repairPoints.SPEAKER_SIDE,
    ANT: repairPoints.ANTENNA,
    BTN_HOME: repairPoints.BUTTON_HOME,
    BTN_POW: repairPoints.BUTTON_POWER,
    SWAP: repairPoints.SCREEN,
    MIC: repairPoints.MICRO,
    CON: repairPoints.MICRO,
    BTN_VOL: repairPoints.BUTTON_VOLUME,
    MULTI: repairPoints.SCREEN,
  }

export default saveRepairPoints
