import { IRepairSelectOption } from '../components/RepairSelect'
import { ProductTypeEnum } from './productTypes'
import { ISaveRepairCode } from './saveRepairCode'

const saveRepairPoints: Record<
  ISaveRepairCode,
  IRepairSelectOption['repairDetail']
> = {
  LCD: {
    [ProductTypeEnum.SMA]: {
      popularRanking: 1,
      icon: '/images/repairs_icons/Icon_LCD.svg',
    },

    [ProductTypeEnum.TAB]: {
      popularRanking: 1,
      icon: '/images/repairs_icons/Icon_LCD.svg',
    },
  },
  BAT: {
    [ProductTypeEnum.SMA]: {
      popularRanking: 2,
      icon: '/images/repairs_icons/Icon_BAT.svg',
    },
    [ProductTypeEnum.TAB]: {
      popularRanking: 2,
      icon: '/images/repairs_icons/Icon_BAT.svg',
    },
  },
  BACK_COVER: {
    [ProductTypeEnum.SMA]: {
      popularRanking: 3,
      icon: '/images/repairs_icons/Icon_BACK_COVER.svg',
    },
    [ProductTypeEnum.TAB]: {
      popularRanking: 3,
      icon: '/images/repairs_icons/Icon_BACK_COVER.svg',
    },
  },

  DIAG: {
    [ProductTypeEnum.SMA]: {
      isService: true,
      icon: '/images/repairs_icons/Icon_DIAG.svg',
    },
    [ProductTypeEnum.TAB]: {
      isService: true,
      icon: '/images/repairs_icons/Icon_DIAG.svg',
    },
  },

  RNL: {
    [ProductTypeEnum.SMA]: {
      isService: true,
      icon: '/images/repairs_icons/Icon_RNL.svg',
    },
    [ProductTypeEnum.TAB]: {
      isService: true,
      icon: '/images/repairs_icons/Icon_RNL.svg',
    },
  },

  DOX: {
    [ProductTypeEnum.SMA]: {
      isService: true,
      icon: '/images/repairs_icons/Icon_DOX.svg',
    },
    [ProductTypeEnum.TAB]: {
      isService: true,
      icon: '/images/repairs_icons/Icon_DOX.svg',
    },
  },
  SWAP: {
    [ProductTypeEnum.SMA]: {
      isService: true,
      icon: '/images/repairs_icons/LCD.svg',
    },
    [ProductTypeEnum.TAB]: {
      isService: true,
      icon: '/images/repairs_icons/LCD.svg',
    },
  },

  CAM_FRONT: {
    [ProductTypeEnum.SMA]: {
      icon: '/images/repairs_icons/Icon_CAM_FRONT.svg',
    },
    [ProductTypeEnum.TAB]: {
      icon: '/images/repairs_icons/Icon_CAM_FRONT.svg',
    },
  },

  CAM_REAR: {
    [ProductTypeEnum.SMA]: {
      icon: '/images/repairs_icons/Icon_CAM_REAR.svg',
    },
    [ProductTypeEnum.TAB]: {
      icon: '/images/repairs_icons/Icon_CAM_REAR.svg',
    },
  },
  TCT: {
    [ProductTypeEnum.SMA]: {
      icon: '/images/repairs_icons/Icon_TCT.svg',
    },
    [ProductTypeEnum.TAB]: {
      icon: '/images/repairs_icons/Icon_TCT.svg',
    },
  },

  BTN_VIB: {
    [ProductTypeEnum.SMA]: {
      icon: '/images/repairs_icons/Icon_VIB.svg',
    },
    [ProductTypeEnum.TAB]: {
      icon: '/images/repairs_icons/Icon_VIB.svg',
    },
  },
  VIB: {
    [ProductTypeEnum.SMA]: {
      icon: '/images/repairs_icons/Icon_VIB.svg',
    },
    [ProductTypeEnum.TAB]: {
      icon: '/images/repairs_icons/Icon_VIB.svg',
    },
  },
  PCONNECTOR: {
    [ProductTypeEnum.SMA]: {
      icon: '/images/repairs_icons/Icon_PCONNECTOR.svg',
    },

    [ProductTypeEnum.TAB]: {
      icon: '/images/repairs_icons/Icon_PCONNECTOR.svg',
    },
  },

  ECO: {
    [ProductTypeEnum.SMA]: { icon: '/images/repairs_icons/Icon_ECO.svg' },

    [ProductTypeEnum.TAB]: { icon: '/images/repairs_icons/Icon_ECO.svg' },
  },
  HP: {
    [ProductTypeEnum.SMA]: { icon: '/images/repairs_icons/Icon_HP.svg' },

    [ProductTypeEnum.TAB]: { icon: '/images/repairs_icons/Icon_HP.svg' },
  },
  'HAUT PARLEUR': {
    [ProductTypeEnum.SMA]: { icon: '/images/repairs_icons/Icon_HP.svg' },

    [ProductTypeEnum.TAB]: { icon: '/images/repairs_icons/Icon_HP.svg' },
  },
  ANT: {
    [ProductTypeEnum.SMA]: { icon: '/images/repairs_icons/Icon_ANT.svg' },

    [ProductTypeEnum.TAB]: { icon: '/images/repairs_icons/Icon_ANT.svg' },
  },
  BTN_HOME: {
    [ProductTypeEnum.SMA]: { icon: '/images/repairs_icons/Icon_BTN_HOME.svg' },

    [ProductTypeEnum.TAB]: { icon: '/images/repairs_icons/Icon_BTN_HOME.svg' },
  },
  BTN_POW: {
    [ProductTypeEnum.SMA]: { icon: '/images/repairs_icons/Icon_BTN_POW.svg' },

    [ProductTypeEnum.TAB]: { icon: '/images/repairs_icons/Icon_BTN_POW.svg' },
  },

  MIC: {
    [ProductTypeEnum.SMA]: { icon: '/images/repairs_icons/Icon_MIC.svg' },

    [ProductTypeEnum.TAB]: { icon: '/images/repairs_icons/Icon_MIC.svg' },
  },
  CON: {
    [ProductTypeEnum.SMA]: {
      icon: '/images/repairs_icons/Icon_PCONNECTOR.svg',
    },

    [ProductTypeEnum.TAB]: {
      icon: '/images/repairs_icons/Icon_PCONNECTOR.svg',
    },
  },
  BTN_VOL: {
    [ProductTypeEnum.SMA]: { icon: '/images/repairs_icons/Icon_BTN_VOL.svg' },

    [ProductTypeEnum.TAB]: { icon: '/images/repairs_icons/Icon_BTN_VOL.svg' },
  },
  OTH: {
    [ProductTypeEnum.SMA]: { icon: '/images/repairs_icons/Icon_OTH.svg' },

    [ProductTypeEnum.TAB]: { icon: '/images/repairs_icons/Icon_OTH.svg' },
  },
  VITRE: {
    [ProductTypeEnum.SMA]: { icon: '/images/repairs_icons/Icon_LCD.svg' },

    [ProductTypeEnum.TAB]: { icon: '/images/repairs_icons/Icon_LCD.svg' },
  },
  MULTI: {},
}

export default saveRepairPoints
