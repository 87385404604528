const colors: Record<string, string> = {
  black: 'Noir',
  blue: 'Bleu',
  red: 'Rouge',
  green: 'Vert',
  white: 'Blanc',
  gold: 'Doré',
  pink: 'Rose',
  purple: 'Violet',
  silver: 'Argenté',
  grey: 'Gris',
  brown: 'Marron',
  yellow: 'Jaune',
}

const formatColorName = (name: string): string => {
  return colors[name] ?? name
}

export default formatColorName
