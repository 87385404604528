import { IRepairSelectOption } from '../components/RepairSelect'
import { GetArrayElementType } from '../utils/GetArrayElementType'

const pointKeys = [
  'CAMERA_FRONT',
  'SPEAKER_FRONT',
  'EARPHONE',
  'SCREEN',
  'SPEAKER_SIDE',
  'BUTTON_HOME',
  'CAMERA_BACK',
  'BUTTON_POWER',
  'MICRO',
  'BATTERY',
  'ANTENNA',
  'BUTTON_VOLUME',
] as const

// front: ../images/RepairSelectFrontSideCropped.png
// back: ../images/RepairSelectBackSideCropped.png
// double: ../images/RepairSelectDesktopCropped.png
const repairPoints: Record<
  GetArrayElementType<typeof pointKeys>,
  IRepairSelectOption['target']
> = {
  CAMERA_FRONT: {
    type: 'front',
    single: [0.58, 0.035],
    double: [0.385, 0.075],
  },
  SPEAKER_FRONT: {
    type: 'front',
    single: [0.67, 0.038],
    double: [0.444, 0.08],
  },
  EARPHONE: {
    type: 'front',
    single: [0.85, 0.022],
    double: [0.57, 0.063],
  },
  SCREEN: {
    type: 'front',
    single: [0.5, 0.45],
    double: [0.33, 0.48],
  },
  SPEAKER_SIDE: {
    type: 'front',
    single: [0.5, 0.91],
    double: [0.35, 0.915],
  },
  BUTTON_HOME: {
    type: 'front',
    single: [0.37, 0.81],
    double: [0.24, 0.82],
  },
  CAMERA_BACK: {
    type: 'back',
    single: [0.5, 0.215],
    double: [0.762, 0.2571],
  },
  BUTTON_POWER: {
    type: 'back',
    single: [0.885, 0.245],
    double: [0.98, 0.286],
  },
  MICRO: {
    type: 'back',
    single: [0.5, 0.946],
    double: [0.762, 0.932],
  },
  BATTERY: {
    type: 'back',
    single: [0.5, 0.65],
    double: [0.762, 0.67],
  },
  ANTENNA: {
    type: 'back',
    single: [0.73, 0.052],
    double: [0.88, 0.105],
  },
  BUTTON_VOLUME: {
    type: 'back',
    single: [0.885, 0.357],
    double: [0.98, 0.39],
  },
}

export default repairPoints
